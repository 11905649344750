<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0;">
        <div class="auth-inner main-container-width">
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">
                    <v-card flat
                            class="full-min-height pb-10">
                        <app-header :details="selectedCard"></app-header>
                        <v-row class="mx-10 mt-9">
                            <v-col cols="12"
                                   lg="12">
                                <v-row class="backbtn mr-4 pb-5">

                                    <BackAction :text="text"
                                                :output="output" />
                                </v-row>
                                <ManageCardCounts :card-detail="cardDetail"></ManageCardCounts>
                            </v-col>
                        </v-row>

                        <ManageCardDetailsBox @manageCard="swapCard($event)"
                                              :card-box-details="accountCards"></ManageCardDetailsBox>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import AppHeader from '../test/AppHeader.vue';
import store from '@/store';
import router from '@/router';
import BackAction from '@/@core/BackAction.vue';
import ManageCardCounts from './ManageCardCounts.vue';
import ManageCardDetailsBox from './ManageCardDetailsBox.vue';
//  import ManageCardActions from './ManageCardActions.vue';

export default {
    components: {
        AppHeader,
        ManageCardCounts,
        ManageCardDetailsBox,
        BackAction
        // ManageCardActions
    },

    data: () => ({
        selectedCard: null,
        accountCards: [],
        cards: [],
        cardDetail: {
            active: 0,
            inActive: 0,
            block: 0,
            expired: 0,
        },
        text: 'Back to Card Details',
        output: false

    }),
    mounted()
    {
        this.accountCards = store.get('cardStore/accountCards');
        this.cards = store.get('cardStore/allCards')
        this.selectedCard = store.get('card/details');
    },

    methods: {
        selectCard()
        {
            router.push('/card-issue')
        },
        back()
        {
            router.push('/card-detail')
        },
        popup()
        {
            router.push('/primary-card')
        },
        swapCard(event)
        {
            this.selectedCard = store.get('card/details');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 146px;
    height: 1.2em;
    white-space: nowrap;
}

.backbtn {
    margin-left: -24px;
}

.area {
    background-color: white;
}

@media (max-width: 500px) {
    .mx-10 {
        margin-right: 11px !important;
        margin-left: 11px !important;
    }
}
</style>
;